
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        








































































































































































































































































































































.custom-table-list {
  font-size: 85%;
}
